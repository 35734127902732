import React, { forwardRef } from 'react';
import Editor from './Editor';
import { Button, Flex } from 'antd';
import "./styles.css"
import Quill from 'quill/quill';

const Delta = Quill.import('delta');

const EditComponent = forwardRef((props, quillRef) => {

    const cleanWorkspace = ()=>{
      quillRef.current?.setContents([])
      quillRef.current?.focus()
    }


  
    return (
      <div>
        <Editor
          ref={quillRef}
          defaultValue={new Delta()
            .insert('Hello')
            .insert('\n', { header: 1 })
            .insert('You can try the new way of writing empowered by ')
            .insert('fuzzy dictionary search.', { bold: true })
            .insert('\n')
            .insert("try to write some word out of alphabetical order then select the word blow and ")
            .insert('enjoy easy writing.', { underline: true })
            .insert('\n')}
        />
        <Flex wrap="wrap" gap="small" className="site-button-ghost-wrapper">
          {/* <Button type="primary" ghost>
            Copy work space
          </Button> */}

          <Button type="primary" danger ghost onClick={cleanWorkspace}>
            Clean work space
          </Button>
        </Flex>
        {/* <div className="controls">
          <label>
            Read Only:{' '}
            <input
              type="checkbox"
              value={readOnly}
              onChange={(e) => setReadOnly(e.target.checked)}
            />
          </label>
          <button
            className="controls-right"
            type="button"
            onClick={() => {
              alert(quillRef.current?.getLength());
            }}
          >
            Get Content Length
          </button>
        </div> */}
        {/* <div className="state">
          <div className="state-title">Current Range:</div>
          {range ? JSON.stringify(range) : 'Empty'}
        </div>
        <div className="state">
          <div className="state-title">Last Change:</div>
          {lastChange ? JSON.stringify(lastChange.ops) : 'Empty'}
        </div> */}
      </div>
    );
}
)

export default EditComponent