import React, {useRef } from 'react'
import EditComponent from '../components/editor/EditComponent'
import { EditPadText,EditPadIdx} from '../store/ConfigContent/ConfigConst';
import { useAppConfig,getAppConfigText} from '../store/ConfigContent/ConfigContext';
import {getWordRangetFromStr} from '../servive/GetWordFromString';
import WordSearchComp from '../components/WordSearchComp';
import QuillContext from '../store/appcontext/QuillContext';


const EditPage = () => {
    // Use a ref to access the quill instance directly
  const quillRef = useRef();
  const appConfig = useAppConfig();

  const padText = getAppConfigText(appConfig,EditPadText)
  const padIdx = getAppConfigText(appConfig,EditPadIdx)

  console.log(padText,padIdx)

  let wordRange = getWordRangetFromStr(padText,padIdx)
  let word = padText.slice(wordRange[0],wordRange[1])

  const handleWordSelection = (selectedword)=>{
    quillRef.current?.deleteText(wordRange[0],wordRange[1]-wordRange[0])
    quillRef.current?.insertText(wordRange[0],selectedword.title + " ")
    console.log(selectedword)
  }

  return (
    <div  style={{paddingTop:'30px'}}>
        <EditComponent ref={quillRef}></EditComponent>
        <QuillContext.Provider value={handleWordSelection}>
          <WordSearchComp word ={word} ></WordSearchComp>
        </QuillContext.Provider>
    </div>
  )
}

export default EditPage