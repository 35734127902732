import React from 'react'

const Contact = () => {
  return (
    <div style={{paddingTop:'30px'}}>
      <h2>About FuzzyDict</h2>


        <p  >Fuzzy dictionary introduces a new way of word search, you can search a word without exactly alphabetical order. For example, you can search "<b>p</b>a<b>l</b>e<b>st</b>ine" with only "plst".</p>

        <p>You can click the search result word to copy the word.</p>

        <br></br>
       
    </div>
  )
}

export default Contact