import React,{useContext} from 'react'
import { List, Space, Button } from 'antd';
import "./SearchResultForEditor.css"
import QuillContext from '../../store/appcontext/QuillContext';

const SearchResultForEditor = ({data}) => {
    const resultData=data.map((word)=>({title:word}))
    const handleSelectedWord = useContext(QuillContext)
    const handleClick =(e)=>{
        handleSelectedWord(e)
    }

  return (
    <List
      dataSource={resultData}
      renderItem={(item) => (
        <Space direction="horizontal" wrap>
          <Button style={{ border: '1px solid #ccc', borderRadius: 4, margin: '3px' }}  className="my-result-item" onClick={()=>handleClick(item)}>
            {item.title}
          </Button>
          <div className="my-result-item">
            {item.description}
          </div>
        </Space>
      )
      }
    />
  )
}



export default SearchResultForEditor