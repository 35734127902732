export default function getWordFromStr(str,idx){
    let ret = ""
    let n = str.length
    if(idx> n  || str.length <1){
        return ret
    }{
        let left =idx-1 
        let right = idx-1 
        while(left >=0 && str[left].toUpperCase() !== str[left].toLowerCase()){
            left -=1 
        }
        while(right<n-1 && str[right].toUpperCase() !== str[right].toLowerCase()){
            right +=1 
        }
        //console.log(left,right, right-left,str.slice(left, right))
        return str.slice(left+1, right)
    }
}

export  function getWordRangetFromStr(str,idx){
    let n = str.length
    if(idx> n  || str.length <3){
        return [n,n]
    }{
        console.log(str,idx)
        let left =idx-1 
        let right = idx-1 
        while(left >=0 && str[left].toUpperCase() !== str[left].toLowerCase()){
            left -=1 
        }
        while(right<n-1 && str[right].toUpperCase() !== str[right].toLowerCase()){
            right +=1 
        }
        //console.log(left,right, right-left,str.slice(left, right))
        return [left+1, right]
    }
}