

class WordSearch {
    constructor() {
      if (WordSearch._instance) {
        return WordSearch._instance
      }
      WordSearch._instance = this;
      

      // ... Your rest of the constructor code goes after this
      //const wordList = wordlist.split('\n');
      this.search=function searchFor(sorg,wordList) {
        const s = sorg.toLowerCase()
        const ret = []
        const slen = s.length
        if(slen <3){
            return ret 
        }
        for(const word of wordList){
            var idx = 0
            for(var i = 0; i < word.length; i++){
              const a = word[i].toLowerCase()
              //console.log(a)
                if(a ===s[idx]){
                    idx +=1
                    if(idx === slen){
                        ret.push([i,word])
                        break
                    }
                }
            }
        }
        ret.sort((a,b)=>a[0] -b[0])
        const ret1 =[]
        let w
        for([idx,w] of ret){
          ret1.push(w)
        }
        return ret1
      }
    }
  }

export default WordSearch;
  