import React,{useState}from 'react'
import { Button, Empty } from 'antd';

import { Modal } from 'antd';


const EmptyMSG = () => {
    const currentUrl = window.location.href;
    const [visible, setVisible] = useState(false);
    let showextra= currentUrl.indexOf("fuzzydict") >=0

    const showModal = () => {
      setVisible(true);
    };
    
    const handleCancel = () => {
      setVisible(false);
    };

    
  return (
    <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data"
            >

        <h2 hidden={showextra}>Please try our new domain <a href="https://fuzzydict.com">https://fuzzydict.com</a></h2>
        <Button onClick={showModal}> Contact us</Button>
        <Modal title="Contact us" open={visible} onCancel={handleCancel}  footer={null} onOk={handleCancel}>
          
            
            <br>
            </br>
      
            <a href='https://t.me/+-SViWiB7bf40MGE1'> Telegram</a>
            <br>
            </br>
            <a href="mailto:scuipio44@gmail.com?subject=SuggestionAboutFuzzyDict">Email</a>
            <br></br>      
            
          </Modal>
        </Empty>
  )
}

export default EmptyMSG