//import logo from './logo.svg';
import './App.css';
import React from "react";
import AppLayout from './AppLayout';
import { ConfigProvider, theme as antdTheme } from 'antd';
import { AppConfigProvider } from './store/ConfigContent/ConfigContext';
import 'quill/quill';
const Quill = require("quill");
const appTheme = {
  ...antdTheme,
  // You can override specific theme variables here
  // like colorPalette.primary: '#222',
};

window.Quill = Quill;
function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
      
    //   </header>
      
    // </div>
    <AppConfigProvider>
      <ConfigProvider theme={appTheme}>
        
        <AppLayout></AppLayout>
        
      </ConfigProvider>
    </AppConfigProvider>
    
  );
}

export default App;
