import React, {useState,useEffect} from 'react'

import { useAppConfig,getAppConfigText} from '../store/ConfigContent/ConfigContext';
import {  NameSpace} from '../store/ConfigContent/ConfigConst';
import getWordList from '../servive/WordListService';
import WordSearch from '../servive/WordSearch'
import SearchResultForEditor from './results/SearchResultForEditor';
let ws= new WordSearch()

const WordSearchComp = ({word}) => {
    const appConfig = useAppConfig();
    const namespace = getAppConfigText(appConfig,NameSpace);

    const [result,setResult] = useState([])

    useEffect(()=>{
        async function startSearch(word){
            const wordlist= await getWordList(namespace,word);
            console.log(ws.search(word,wordlist).length)
            setResult(ws.search(word,wordlist));
          }
        if(word && word.length>2){
            startSearch(word)
        }
        
    },[word,namespace]);




  return (
    <SearchResultForEditor data ={result}  ></SearchResultForEditor>
  )
}

export default WordSearchComp