import React from 'react'
import {  List } from 'antd';
import Mycard from './Mycard';
import EmptyMSG from './EmptyMSG';


const SearchResult = ({data}) => {
    const resultData=data.map((word)=>({title:word}))
  return (
    <List 
    grid={{  gutter: 16,
      xs: 1,
      sm: 2,
      md: 4,
      lg: 4,
      xl: 6,
      xxl: 8, }}
      locale={{
        emptyText: (
            
              <EmptyMSG></EmptyMSG>
           
        ),
    }}
    dataSource={resultData}
    renderItem={(item) => (
      <List.Item >
        <Mycard item ={item} ></Mycard>
      </List.Item>
    )}
  />
  )
}

export default SearchResult