export default function getIndex(arr){
    let ret = 0 
    if(arr.length===1){
        ret +=1
    }else{
        ret = arr[0].retain 
        if(arr[1].insert){
            ret +=1
        }
    }
    return ret
}