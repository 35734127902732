import React from 'react'
import './Info.css'
const InfoPage = () => {
  return (
    <div style={{paddingTop:'30px'}}>
      <h2>About FuzzyDict</h2>

        <div>
        <p>Fuzzy dictionary introduces a new way of word search, you can search a word without exactly alphabetical order. For example, you can search "<b>p</b>a<b>l</b>e<b>st</b>ine" with only "plst".</p>

        <p>You can click the search result word to copy.</p>
        </div>
        
        <h2>Tips</h2>
        <div>
        <p>You can install the application[the install button in the address bar] as chrome app for easy access.</p>
        <p>There are "Eng" and "EngPro" tab for word searching pattern, "EngPro" contains about 40k words for seach, "Eng" contains about 10k words.</p>
        </div>
        <h2>More dictionary to be added?</h2>
        <p>If you want to add more language dictionary, email to scuipio44@gmail.com.</p>

    </div>
  )
}

export default InfoPage