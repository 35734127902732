import React, {useState} from 'react'
import Input from 'antd/es/input/Input'
import WordSearch from '../servive/WordSearch'
import SearchResult from '../components/SearchResult'
import { Flex } from 'antd';
import CopyButton from '../components/CopyButton';
import { useAppConfig,getAppConfigText } from '../store/ConfigContent/ConfigContext';
import { NameSpace } from '../store/ConfigContent/ConfigConst';
import getWordList from '../servive/WordListService';
let ws= new WordSearch()
const SearchPage = () => {
  const appConfig = useAppConfig();
  const namespace = getAppConfigText(appConfig,NameSpace);
  const [result,setResult] = useState([])
  async function startSearch(e){
    //console.log(e.target.value)
    const wordlist= await getWordList(namespace,e.target.value);
    setResult(ws.search(e.target.value,wordlist));
  }
  return (
    <div style={{width: '100%'}}>
        <h1>Fuzzy word search</h1>
        <Flex  gap="middle" horizontal='true'>
          <Input style={{marginBottom:'1em'}}  placeholder="Type here to search word..." allowClear  autoFocus onChange={startSearch}></Input>
          <CopyButton></CopyButton>
        </Flex>
        
        <SearchResult data ={result}  ></SearchResult>
    </div>
  )
}

export default SearchPage