import wordlist from "../service/mitword";
import { Eng } from "../store/ConfigContent/ConfigConst";
import LRUCache from "./LCUCache";
import axios from 'axios';

const lcu = new LRUCache(100)
const apiendpoint =  "https://fuzzydict.com/wordsearch"
const wordListBasic = wordlist.split('\n');
export default async  function getWordList(namesapce,pre){
    if(namesapce ===Eng ){
        return wordListBasic;
    }else{
        if(pre.length <3){
            return [];
        }else{
            const preIdx = pre.substring(0,3);
            const key = namesapce + preIdx;
            if(lcu.get(key) === -1){
                const apiurl = apiendpoint +"?pre=" + preIdx + "&namespace="+namesapce  ;
                let wordlist=[];
                await axios.get(apiurl).then(data => {
                    wordlist = data.data.result;
                    lcu.put(key,wordlist);
                })
                .catch(err=>console.log(err));
                return wordlist;
            }else{
                return lcu.get(key);
            }
        }
    }
}