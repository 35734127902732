import React from 'react'
import { Layout, Menu } from 'antd';
import "./AppHeader.css"
import {useState} from 'react';
import NavbarSwitch from './NavbarSwitch';
import {Link} from  "react-router-dom";
const { Header } = Layout;
const AppHeader = () => {

    const [current, setCurrent] = useState('home');
    const shareMe = async ()=>{
      await navigator.clipboard.writeText("This web defines new way of writing and word searching, you can search a word without exactly alphabetical order. Try it on http://fuzzydict.com")
      alert("Share message copied, you can send the share message to your friends now!")
    }

    const items = [
      { key: 'home', label: <Link to= '/'>Home</Link>  }, // Use href for absolute URLs
      { key: 'dict', label: <Link to= '/dict'>Dictionary</Link> }, // Use href for external links
      { key: 'info', label: <Link to= '/info'>About</Link> }, // Use href for external links
      { key: '', label:<div href='/' onClick={shareMe}>Share</div>}, 
    ];



    const onClick = (e) => {
      setCurrent(e.key);
    };

  return (
    <Layout> 
    <Header  style={{  display: 'flex', justifyItems: 'space-between' }}>
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      <NavbarSwitch></NavbarSwitch>
    </Header>
    </Layout>
  );
};



export default AppHeader