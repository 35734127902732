import React from 'react'
import { Button,Tooltip  } from 'antd';
import {CopyOutlined } from  '@ant-design/icons';
import { CopyValue } from '../store/ConfigContent/ConfigConst';
import { useAppConfig,getAppConfigText} from '../store/ConfigContent/ConfigContext';

const CopyButton = () => {
    const appConfig = useAppConfig();
    const value = getAppConfigText(appConfig,CopyValue)
    if(value===""){
        return <Tooltip title="Nothing copied"><Button  type="text" disabled>Empty copy</Button></Tooltip>    
    }
     else{
        return <Tooltip title="Copied the txt "><Button   icon={<CopyOutlined /> }  type="primary" >{value}</Button></Tooltip>      
     }
}

export default CopyButton