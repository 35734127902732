import React from 'react'
import { Card } from 'antd';
import { CopyValue } from '../store/ConfigContent/ConfigConst';
import { useAppConfigDispatch } from '../store/ConfigContent/ConfigContext';

const Mycard = ({item}) => {
  const dispatch = useAppConfigDispatch();
  const handleClick=(e)=>{
    navigator.clipboard.writeText(e.title)
    dispatch( {type:"update",payload:{id:CopyValue,text:e.title}})
  }
  return (
    <Card onClick={()=>handleClick(item)} title={item.title}  styles={{ body:{display: 'none' } }} ></Card>
  )
}

export default Mycard