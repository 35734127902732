import React, { forwardRef, useEffect, useRef } from 'react';
import Quill from 'quill/quill';
import { useAppConfigDispatch } from '../../store/ConfigContent/ConfigContext';
import { EditPadText,EditPadIdx} from '../../store/ConfigContent/ConfigConst';
import getIndex from '../../servive/GetIndex';
// Editor is an uncontrolled React component
const Editor = forwardRef(
  ({ defaultValue}, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);


    const dispatch = useAppConfigDispatch();

    // useLayoutEffect(() => {
    //   onTextChangeRef.current = onTextChange;
    //   onSelectionChangeRef.current = onSelectionChange;
    // });

    // useEffect(() => {
    //   ref.current?.enable(!readOnly);
    // }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        theme: 'snow',
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        // onTextChangeRef.current?.(...args);
        //console.log(ref.current?.getText());
        if(args){
          dispatch({type:"update",payload:{id:EditPadIdx,text:getIndex(args[0].ops)}})
          dispatch({type:"update", payload:{id:EditPadText,text:ref.current?.getText()}})
        }
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        // onSelectionChangeRef.current?.(...args);
        if(args && args[0] && args[0].length ===0){
          dispatch({type:"update",payload:{id:EditPadIdx,text:args[0].index}})
        }
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref,dispatch]);

    return <div ref={containerRef}></div>;
  },
);

Editor.displayName = 'Editor';

export default Editor;